*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* antd mobile */
.colorPrimary {
  --adm-color-primary: #75BD76;
}
.colorPrimaryDisabled {
  --adm-color-primary: gray;
}
/* antd */
.modalLoading .ant-spin-dot-item{
  color:#75BD76;
}
.ant-spin-text{
  color:#75BD76;
} 